import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import { Button, Text, ProductFeatureSection } from '../components';
export const metadata = {
  title: 'Point of Sale'
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div>
    <div>
        <Button onClick={() => window.location = "https://terminal.cheapreats.com/"} style={{
          marginLeft: 'auto',
          display: 'flex'
        }} mdxType="Button">Visit Point of Sale Web Application</Button>
    </div>
    <div>
        <Text size="large" as="h1" mdxType="Text">A Modern Point of Sale</Text>
        <Text size="small" as="h2" mdxType="Text">Built for every vendor, without sacrifices</Text>
    </div>
    <div>
        <Button mdxType="Button">Sign up now!</Button>
        <Button mdxType="Button">Need Assistance? Contact us and we'll jump on a call to help you get started</Button>
    </div>
    <ProductFeatureSection title={'Quickly Respond to All Your Incoming Orders'} body={'Quick Response allows you to let your customers know exactly when their meal will be ready'} image={''} mdxType="ProductFeatureSection" />
    <ProductFeatureSection title={'Manage Your Inventory on the Fly'} body={'Mark items as sold out for the day, set live inventory levels & more. Batch Update Inventory Levels or create Custom Flash Sales to capture unrealized items that could spoil at the end of the day.'} image={''} mdxType="ProductFeatureSection" />
    <ProductFeatureSection title={'Built-in Time Tracking to Clock In & Out for Your Employees'} body={'Allows employees to clock in and out of your store to measure employee performance.'} image={''} mdxType="ProductFeatureSection" />
    <ProductFeatureSection title={'Customize Everything, Even Sounds'} body={'Customize the sound your point of sale makes when a new order arrives, Dark Mode / Light Mode.'} image={''} mdxType="ProductFeatureSection" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      